import axios from 'axios'

import { ICourseEnrollment, ICourseEnrollmentResponse } from '.'
import { ICoursePermission, ICoursePermissionResponse, IEnrollmentHistory } from './types'
import { ConvertType, convertCaseList } from '@/shared/utils/convertString'
import axiosInstance from '@/shared/utils/axios'

export const requestEnrollment = async (courseId: string) => {
  const resp = await axiosInstance.get<ICourseEnrollmentResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/permission/v2/enrollments/courses/${courseId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as ICourseEnrollment
}

export const requestCoursePermission = async (courseId: string) => {
  const resp = await axiosInstance.get<ICoursePermissionResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/permission/v1/enrollments/courses/${courseId}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as ICoursePermission
}

export const requestCourseSinglePermission = async (courseId: string) => {
  const resp = await axiosInstance.get<IListDataResp<IEnrollmentHistory>>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/my/permission/v1/enrollments?course_id=${courseId}&page=1&per_page=1`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}
