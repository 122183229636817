import { useQuery } from '@tanstack/react-query'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

import axiosInstance from '@/shared/utils/axios'

export const fetchRole = async () => {
  const resp = await axiosInstance.get(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/roles`)
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const useRoleQuery = () => {
  return useQuery({
    queryKey: ['role'],
    queryFn: () => fetchRole(),
    select: data => data.roles,
    initialData: null,
  })
}
