import { ConvertType, convertCaseList } from '@/shared/utils/convertString'
import { IProfile, IProfileResponse, IProfileUpdate, ITermsOfServiceUpdateParams } from './types'
import axiosInstance from '@/shared/utils/axios'

export const requestProfile = async () => {
  const resp = await axiosInstance.get<IProfileResponse>(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL) as IProfile
}

export const requestRole = async () => {
  const resp = await axiosInstance.get(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/roles`)
  return convertCaseList(resp.data.roles, ConvertType.CAMEL) as string[]
}

export const requestUpdateProfile = async (data: IProfileUpdate) => {
  const resp = await axiosInstance.post(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/profiles`,
    convertCaseList(data, ConvertType.SNAKE),
  )

  return resp.data
}

export const requestCheckPassword = async (password: string) => {
  const resp = await axiosInstance.post(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/check-password`,
    { password },
  )

  return resp.data
}

export const requestChangePassword = async (password: string) => {
  const resp = await axiosInstance.post(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/change-password`,
    { password },
  )

  return resp.data
}

export const requestFetchTermsOfService = async () => {
  const resp = await axiosInstance.get(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/terms-of-service`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestUpdateTermsOfService = async (data: ITermsOfServiceUpdateParams) => {
  const resp = await axiosInstance.patch(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/me/terms-of-service`,
    convertCaseList(data, ConvertType.SNAKE),
  )

  return resp.data
}
